import axios from "axios";
import AthenaSettings, { AthenaSettingsKeys } from "./athena_settings";

const baseURLS = {
    dev: 'http://127.0.0.1:8000/api/',
    devIcplBE: 'http://127.0.0.1:8000/api/',
    prodIntegration: 'https://findplus.w-locate.com:8443/integration/',
    prodKHScheduler: 'https://findplus.w-locate.com/Kimhock/Scheduler/',
    prodIcplBE: 'https://icplapi.chooseathena.com/api/',
}

export const baseURL = baseURLS.prodIntegration;
export const axiosInstance = axios.create({
    baseURL: baseURL
});

axiosInstance.interceptors.request.use(config => {
    const settings = new AthenaSettings();
    const account = settings.getItem(AthenaSettingsKeys.account);
    if (account) {
        const token = account.ApiKey;
        config.headers.Token = token;
    }
    return config;
});

export const axiosICPLBEInstance = axios.create({
    baseURL: baseURLS.devIcplBE
});

axiosICPLBEInstance.interceptors.request.use(config => {
    const settings = new AthenaSettings();
    const account = settings.getItem(AthenaSettingsKeys.account);
    if (account) {
        const token = account.token;
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export const axiosKHSchedulerInstance = axios.create({
    baseURL: baseURLS.prodKHScheduler
});

axiosKHSchedulerInstance.interceptors.request.use(config => {
    const settings = new AthenaSettings();
    const account = settings.getItem(AthenaSettingsKeys.account);
    if (account) {
        const token = account.ApiKey;
        config.headers.Token = token;
    }
    return config;
});