import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Power } from 'react-feather';
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useDispatch } from "react-redux";
import { logoutAccount } from "../container/login/redux/login_slice";
import { RouterRoutes } from "../App";
import Footer from "./footer";

export default function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (location.pathname === "/") navigate(RouterRoutes.jobCycle);
        // eslint-disable-next-line
    }, []);

    const handleLogout = () => {
        dispatch(logoutAccount());
        navigate(RouterRoutes.login);
    }

    return (
        <>
            <nav className="nav">
                <div className="logo">
                    <img src="assets/W-locate_.png" alt="WLocate" />
                </div>
                <div className="actions">
                    <TooltipComponent position="TopLeft" content="Logout" target="#logout" openDelay={800}>
                        <Power id="logout" color="red" style={{ cursor: "pointer" }} onClick={handleLogout} />
                    </TooltipComponent>
                </div>
            </nav>
            <main style={{ padding: '20px', margin: '53px 0', paddingBottom: '70px' }}>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}