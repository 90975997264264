import { createSlice } from '@reduxjs/toolkit';
import StateSelector from '../../../app/selector';
import moment from 'moment';

export const jobCycleUseFormDefaultVal = {
    DateStart: moment().set('hours', 0).set('minutes', 0).toISOString(),
    DateEnd: moment().set('hours', 0).set('minutes', 0).add(1, 'days').toISOString(),
    Vehicle: '',
    PlantName: '',
    Driver: '',
    OrderCode: '',
    ShipToCode: ''
};

const initialState = {
    filter: jobCycleUseFormDefaultVal,
    rawJobCycles: [],
    vehicles: [],
    origins: [],
    drivers: [],
    downloadExcel: 1,
}

export const jobCycleSlice = createSlice({
    name: 'jobCycle',
    initialState: initialState,
    reducers: {
        jobCycleReset: () => {
            return initialState;
        },
        setRawJobCycles: (state, action) => {
            state.rawJobCycles = action.payload;
        },
        setVehicles: (state, action) => {
            state.vehicles = action.payload;
        },
        setOrigins: (state, action) => {
            state.origins = action.payload;
        },
        setDrivers: (state, action) => {
            state.drivers = action.payload;
        },
        setJobCycleFilter: (state, action) => {
            state.filter = action.payload;
        },
        downloadJobCycleExcel: (state, action) => {
            state.downloadExcel += 1;
        }
    }
})

export const { 
    jobCycleReset, 
    setRawJobCycles,
    setVehicles,
    setDrivers,
    setOrigins,
    setJobCycleFilter,
    downloadJobCycleExcel } = jobCycleSlice.actions;

export const jobCycleSelector = StateSelector('jobCycle');

export default jobCycleSlice.reducer