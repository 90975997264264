import moment from "moment";

function JobCycleFilterUtils(data) {
    this.filter = data;

    this.getFilterHeader = function () {
        let headerTitle = "Filter";
        const keys = Object.keys(this.filter);
        if (keys.length) {
            headerTitle += ' - ';
            keys.forEach((key) => {
                if (this.filtersLabels[key] && this.filter[key]) {
                    if (key === 'DateStart' || key === 'DateEnd') headerTitle += `${this.filtersLabels[key]}: ${moment(this.filter[key]).format('yyyy-MMM-DD HH:mm')}, `
                    else headerTitle += `${this.filtersLabels[key]}: ${this.filter[key]}, `
                };
            });

            return headerTitle.substring(0, (headerTitle.length - 2));
        }

        return headerTitle;
    }
    this.mapToExcel = function () {
        let mapped = [];
        const keys = Object.keys(this.filter);
        if (keys.length) {
            keys.forEach((key) => {
                if (this.filtersLabels[key] && this.filter[key]) {
                    if (key === 'DateStart' || key === 'DateEnd') {
                        mapped.push({
                            title: this.filtersLabels[key],
                            value: moment(this.filter[key]).toISOString(),
                            isDateTime: true
                        });
                    }
                    else {
                        mapped.push({
                            title: this.filtersLabels[key],
                            value: this.filter[key],
                            isDateTime: false
                        });
                    }
                };
            });
        }

        return mapped;
    }
}

JobCycleFilterUtils.prototype.filtersLabels = {
    DateStart: 'Start',
    DateEnd: 'End',
    Vehicle: 'Vehicle',
    Driver: 'Driver',
    PlantName: 'Job Origin',
    OrderCode: "Order Code",
    ShipToCode: "Ship to Code"
}

export default JobCycleFilterUtils;