import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { downloadJobCycleExcel, jobCycleSelector } from "../../../container/job_cycle/redux/job_cycle_slice";
import useIsMobile from "../../../utils/use_is_mobile";
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const items = [
    {
        text: 'Download Excel',
    }
];

export default function FooterTableActions() {
    const dispatch = useDispatch();
    const { rawJobCycles } = useSelector(jobCycleSelector(['rawJobCycles']), shallowEqual);
    const { isMobile } = useIsMobile();

    const hasJobCycles = rawJobCycles.length > 0;

    const handleDropdownSelect = e => {
        //See items
        //Download Excel
        if (e.item.text === items[0].text) {
            handleExcelOnClick();
        }
    }

    const handleExcelOnClick = () => {
        dispatch(downloadJobCycleExcel());
    }

    return (
        <>
             {isMobile && <DropDownButtonComponent cssClass='e-primary' select={handleDropdownSelect} items={items} disabled={!hasJobCycles} style={{ marginRight: '10px' }}> Actions </DropDownButtonComponent>}
            {!isMobile && <ButtonComponent cssClass='e-primary' items={items} onClick={handleExcelOnClick} disabled={!hasJobCycles} style={{ marginRight: '10px' }}> Download Excel </ButtonComponent>}
            
        </>
    );
}