import { memo, useState } from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useTranslation } from 'react-i18next';
import Spacing from '../../../components/Spacing';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import Divider from '../../../components/Divider';
import { Controller, useForm } from 'react-hook-form';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import FindPlusIntegration from '../../../services/findplus_integration';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { setAccount, setShowLoginForm } from '../redux/login_slice';
import { Link, useNavigate } from 'react-router-dom';
import { RouterRoutes } from '../../../App';
import AthenaSettings, { AthenaSettingsKeys } from '../../../utils/athena_settings';
import { useRef } from 'react';

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setSubmit] = useState(false);

    const submitBtnRef = useRef();
    const isLoggingIn = useRef(false);

    const { t } = useTranslation();
    const { control, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            username: '',
            password: '',
            rememberMe: false
        }
    });

    const isFieldError = value => {
        return value === undefined ? false : value !== null && value !== "";
    };

    const errorTextBoxClass = value => {
        let className = 'e-outline';
        if (isFieldError(value)) className += ' e-error'

        return className;
    }

    const errorLabelClass = value => {
        let className = 'field-error-message';
        if (!isFieldError(value)) className += ' hide'

        return className;
    }

    const onSubmit = async payload => {
        if (!isLoggingIn.current) {
            isLoggingIn.current = true;
            try {
                setSubmit(true);
                const isRememberMe = payload.rememberMe;
                delete payload.rememberMe;

                const accountService = FindPlusIntegration.getAccountService();
                const response = await accountService.authenticate(payload);
                if (response.status < 400) {
                    const settings = new AthenaSettings();
                    settings.setItem(AthenaSettingsKeys.rememberMe, {
                        value: isRememberMe,
                        date: new Date().toISOString()
                    });
                    const data = response.data;

                    if (data.UserIDEx.includes('')) {
                        dispatch(setAccount(data));
                        navigate(RouterRoutes.jobCycle);
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Unauthorized Account',
                        });
                    }
                }
            } catch (error) {
                const response = error?.response;
                Swal.fire({
                    icon: 'error',
                    title: 'Login Error',
                    text: response?.status === 400 ? 'Invalid Username or Password' : `Unknown Error (${response.status})`
                });
            }
            isLoggingIn.current = false;
            setSubmit(false);
        }
    }

    return (
        <>
            <div className="flex column">
                <Spacing />
                <Controller
                    name="username"
                    control={control}
                    rules={{
                        required: t('login.invalidUsername')
                    }}
                    render={({ field }) => {
                        const errorMessage = errors.username?.message;
                        return (
                            <>
                                <TextBoxComponent
                                    {...field}
                                    id='txtUsername'
                                    cssClass={errorTextBoxClass(errorMessage)}
                                    placeholder={t('login.usernameLabel')}
                                    created={() => {
                                        document.getElementById('txtUsername').addEventListener('keypress', e => {
                                            if (e.code === 'Enter' && submitBtnRef.current) {
                                                field.onChange(e.target.value);
                                                submitBtnRef.current.click();
                                            }
                                        });
                                    }}
                                    floatLabelType='Always' />
                                <label className={errorLabelClass(errorMessage)}>{errorMessage}</label>
                            </>
                        );
                    }} />
                <Spacing />
                <Controller
                    name="password"
                    control={control}
                    rules={{
                        required: t('login.invalidPassword')
                    }}
                    render={({ field }) => {
                        const errorMessage = errors.password?.message;
                        return (
                            <>
                                <TextBoxComponent
                                    {...field}
                                    id='txtPassword'
                                    type='password'
                                    cssClass={errorTextBoxClass(errorMessage)}
                                    placeholder={t('login.passwordLabel')}
                                    created={() => {
                                        document.getElementById('txtPassword').addEventListener('keypress', e => {
                                            if (e.code === 'Enter' && submitBtnRef.current) {
                                                field.onChange(e.target.value);
                                                submitBtnRef.current.click();
                                            }
                                        });
                                    }}
                                    floatLabelType='Always' />
                                <label className={errorLabelClass(errorMessage)}>{errorMessage}</label>
                            </>
                        )
                    }} />
                <Spacing />
                <Controller
                    name="rememberMe"
                    control={control}
                    render={({ field }) => (
                        <CheckBoxComponent
                            checked={field.value}
                            label={t('login.rememberMeLabel')} />
                    )} />
                <Spacing />
                <ProgressButtonComponent
                    ref={submitBtnRef}
                    cssClass="e-primary"
                    content={t('login.loginBtnTitle')}
                    duration={!isSubmitting ? 800 : 100000}
                    spinSettings={{
                        position: 'Center'
                    }}
                    animationSettings={{
                        effect: 'ZoomOut'
                    }}
                    onClick={handleSubmit(onSubmit)}>
                </ProgressButtonComponent>
                <Divider />
                <div className="center-content">
                    <Link className='forgot-password' onClick={() => dispatch(setShowLoginForm(false))}>{t('login.forgotPassword')}</Link>
                </div>
                <Divider />
                <div className="logo-container">
                    <img src="assets/find.png" alt="Powered by Find" />
                </div>
            </div>
        </>
    );
}

export default memo(LoginForm);