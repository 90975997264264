import { useEffect } from 'react';
import JobCycleFilter from './components/Filter';
import { useDispatch } from 'react-redux';
import { jobCycleReset, setOrigins, setVehicles } from './redux/job_cycle_slice';
import FindPlusIntegration from '../../services/findplus_integration';
import Swal from 'sweetalert2';
import { JobCycleTable } from './components/Table';
import JobCycleExcel from './components/Excel';
import Spacing from '../../components/Spacing';

export default function JobCyclePage() {
    const dispatch = useDispatch();

    useEffect(() => {
        initService();
        return () => dispatch(jobCycleReset());
        // eslint-disable-next-line
    }, []);

    const initService = async () => {
        Swal.fire({
            icon: 'info',
            title: 'Please wait...',
            allowOutsideClick: !Swal.isLoading(),
            didOpen: async () => {
                Swal.showLoading();
                try {
                    const vehicleService = FindPlusIntegration.getVehicleService();
                    const zoneService = FindPlusIntegration.getZoneService();
                    // const driverService = FindPlusIntegration.getDriverService();

                    const origins = await zoneService.getOrigins();

                    const vehiclePayload = {
                        PageNumber: 1,
                        PageSize: 100
                    }
                    let canFetch = false;
                    let vehicles = [];

                    do {
                        const vehiclesReq = await vehicleService.getVehicles(vehiclePayload);
                        const vehicleData = vehiclesReq.data;
                        canFetch = vehicleData.length > 0 && vehicleData.length === vehiclePayload.PageSize;
                        vehicles = vehicles.concat(vehicleData);
                        if (canFetch) {
                            vehiclePayload.PageNumber += 1;
                            vehiclePayload.ItemID = vehicleData[vehicleData.length - 1].VehicleID;
                        }
                    } while (canFetch);

                    dispatch(setVehicles(vehicles));
                    dispatch(setOrigins(origins.data));
                    // dispatch(setDrivers(driver.data));

                    Swal.close();
                } catch (_) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to fetch data',
                        text: 'Try again'
                    });
                }
            }
        });
    }

    return (
        <>
            <JobCycleFilter />
            <Spacing />
            <JobCycleTable />
            <JobCycleExcel />
        </>
    );
}