import IntegrationAccountService from "./controllers/account";
import IntegrationCustomerService from "./controllers/customer";
import IntegrationDriverService from "./controllers/driver";
import IntegrationJobCycleService from "./controllers/job_cycle";
import IntegrationProofService from "./controllers/proof";
import IntegrationVehicleService from "./controllers/vehicles";
import IntegrationZoneService from "./controllers/zone";

function FindPlusIntegration() {}

FindPlusIntegration.getAccountService = () => new IntegrationAccountService();
FindPlusIntegration.getVehicleService = () => new IntegrationVehicleService();
FindPlusIntegration.getDriverService = () => new IntegrationDriverService();
FindPlusIntegration.getZoneService = () => new IntegrationZoneService();
FindPlusIntegration.getCustomerService = () => new IntegrationCustomerService();
FindPlusIntegration.getProofService = () => new IntegrationProofService();
FindPlusIntegration.getJobCycleService = () => new IntegrationJobCycleService();

export default FindPlusIntegration;
