import { useState, memo } from "react";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AccordionComponent, AccordionItemDirective, AccordionItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import DropdownFiltering from "../../../utils/syncfusion/dropdown_filtering";
import { Grid, Stack } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { jobCycleUseFormDefaultVal, setJobCycleFilter, setRawJobCycles } from "../redux/job_cycle_slice";
import Loader from "../../../components/Loader";
import { useRef } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import JobCycleFilterUtils from "../utils/filter";
import moment from "moment";
import Swal from "sweetalert2";
import { jobCycleSelector } from "../redux/job_cycle_slice";
import FindPlusIntegration from "../../../services/findplus_integration";

const JobCycleFilterForm = forwardRef((_, ref) => {
    const dispatch = useDispatch();
    const { vehicles, origins } = useSelector(jobCycleSelector(['vehicles', 'origins']), shallowEqual);
    const [isFetching, setFetching] = useState(false);

    const isRequestInit = useRef();
    const submitRef = useRef();

    const { t } = useTranslation();
    const { control, handleSubmit, getValues, reset } = useForm({
        defaultValues: jobCycleUseFormDefaultVal
    });

    useImperativeHandle(ref, () => {
        return {
            getFilter() {
                return getValues();
            },
            setFilter(data) {
                reset(data);
            }
        }
    });

    // const triggerSearchPrev = useRef(1);
    // useEffect(() => {
    //     //When accordion is closed/open the useRef values is refreshed to default
    //     if ((triggerSearch - triggerSearchPrev.current) > 2) triggerSearchPrev.current = triggerSearch;

    //     //prevent from click from triggering multiple times
    //     if (triggerSearch > triggerSearchPrev.current && !isRequestInit.current) {
    //         triggerSearchPrev.current = triggerSearch;
    //         submitRef.current.click();
    //     }
    //     // eslint-disable-next-line
    // }, [triggerSearch]);

    // useEffect(() => {
    //     customerOnChange();
    //     // eslint-disable-next-line
    // }, [watchCustomerAccNo]);

    // const customerOnChange = async () => {
    //     try {
    //         setValue('ShipToCode', '');
    //         if (watchCustomerAccNo && destinations.filter(f => f.OwnerCustomerCode === watchCustomerAccNo).length === 0) {
    //             if (projectSiteRef.current) projectSiteRef.current.showSpinner();
    //             const zoneService = KHSchedulerService.getZoneService();
    //             const projectSitesReq = await zoneService.getDestination(watchCustomerAccNo);
    //             const projectSites = projectSitesReq.data;
    //             dispatch(setDestinations(destinations.concat(projectSites)));
    //             if (projectSiteRef.current) projectSiteRef.current.hideSpinner();
    //         }
    //     } catch (error) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Unable to fetch destinations.',
    //             text: 'Try again'
    //         });
    //         setValue('CustomerAccNo', '');
    //         setValue('CustomerName', '');
    //     }
    // }

    const onSubmit = async payload => {
        if (!isRequestInit.current) {
            setFetching(true);
            isRequestInit.current = true;

            //to prevent from rerendering page when textSearch value is changed
            // const { textSearch } = icplStore(store, ['textSearch']);

            // if (textSearch) payload.TextSearch = textSearch;
            // if (payload.DeliveryStatus) payload.DeliveryStatus = payload.DeliveryStatus.toUpperCase();

            Object.keys(payload).forEach(key => {
                if (!payload[key]) delete payload[key];
                else if (['DateEnd', 'DateStart'].includes(key)) {
                    payload[key] = moment(payload[key]).toISOString();
                }
            });

            let jobCycles = [];
            let currentPage = 1;
            let proceed = false;

            try {
                dispatch(setJobCycleFilter({ ...payload }));

                do {
                    payload.PageNumber = currentPage;

                    const jobCycleService = FindPlusIntegration.getJobCycleService();
                    const jobCycleReq = await jobCycleService.getRecords(payload);

                    const result = jobCycleReq.data;
                    jobCycles = jobCycles.concat(result);

                    currentPage += 1;
                    proceed = result.length > 0;
                    if (proceed) payload.ItemID = result[result.length - 1].JobCycleID;
                } while (proceed);
            } catch (e) {
                console.log(e);
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to fetch job cycle data',
                    text: 'Try again'
                });
            }

            setTimeout(() => {
                dispatch(setRawJobCycles(jobCycles))
            }, 1000);
            isRequestInit.current = false;
            setFetching(false);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="DateStart"
                    control={control}
                    render={({ field }) => (
                        <DateTimePickerComponent
                            value={field.value}
                            cssClass="e-outline"
                            className="datepicker-transparent"
                            allowEdit={true}
                            floatLabelType='Always'
                            showClearButton={false}
                            format="yyyy-MMM-dd HH:mm"
                            timeFormat="HH:mm"
                            placeholder={t('jobCycle.startLabel')}
                            disabled={isFetching}
                            readonly={isFetching}
                            change={(args) => {
                                field.onChange({
                                    target: { value: args.value }
                                });
                            }}
                        // value={formData.startDateTime}
                        // onChange={onChangeHandler('startDateTime')} 
                        />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="DateEnd"
                    control={control}
                    render={({ field }) => (
                        <DateTimePickerComponent
                            value={field.value}
                            cssClass="e-outline"
                            className="datepicker-transparent"
                            allowEdit={true}
                            floatLabelType='Always'
                            showClearButton={false}
                            format="yyyy-MMM-dd HH:mm"
                            timeFormat="HH:mm"
                            placeholder={t('jobCycle.endLabel')}
                            disabled={isFetching}
                            readonly={isFetching}
                            change={(args) => {
                                field.onChange({
                                    target: { value: args.value }
                                });
                            }}
                        // value={formData.startDateTime}
                        // onChange={onChangeHandler('startDateTime')} 
                        />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
                <Controller
                    name="Vehicle"
                    control={control}
                    render={({ field }) => (
                        <DropDownListComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Always'
                            dataSource={vehicles}
                            autofill={true}
                            readonly={isFetching}
                            showClearButton={true}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, vehicles, 'Name')}
                            fields={{
                                text: 'Name',
                                value: 'Name'
                            }}
                            placeholder={t('jobCycle.vehiclesLabel')} />
                    )} />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="Driver"
                    control={control}
                    render={({ field }) => (
                        <DropDownListComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Always'
                            dataSource={drivers}
                            autofill={true}
                            readonly={isFetching}
                            showClearButton={true}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, drivers, 'Name')}
                            fields={{
                                text: 'Name',
                                value: 'Name'
                            }}
                            placeholder={t('jobCycle.driverLabel')} />
                    )} />
            </Grid> */}
            <Grid item xs={12} sm={12} md={2}>
                <Controller
                    name="PlantName"
                    control={control}
                    render={({ field }) => (
                        <DropDownListComponent
                            {...field}
                            cssClass="e-outline"
                            floatLabelType='Always'
                            dataSource={origins}
                            autofill={true}
                            readonly={isFetching}
                            showClearButton={true}
                            allowFiltering={true}
                            filtering={(args) => DropdownFiltering(args, origins, 'Name')}
                            fields={{
                                text: 'Name',
                                value: 'Name'
                            }}
                            placeholder={t('jobCycle.jobOriginLabel')} />
                    )} />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
                <Controller
                    name="OrderCode"
                    control={control}
                    render={({ field }) => (
                        <TextBoxComponent
                            {...field}
                            cssClass="e-outline"
                            readonly={isFetching}
                            floatLabelType="Always"
                            placeholder={t('jobCycle.orderCodeLabel')} />
                    )} />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={3}>
                <Controller
                    name="ShipToCode"
                    control={control}
                    render={({ field }) => (
                        <TextBoxComponent
                            {...field}
                            cssClass="e-outline"
                            readonly={isFetching}
                            floatLabelType="Always"
                            placeholder={t('jobCycle.shipToCodeLabel')} />
                    )} />
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>
                <div className="center-content">
                    <Stack spacing={2} direction={'row'}>
                        <ButtonComponent
                            cssClass="e-warning"
                            readonly={isFetching}
                            onClick={() => reset(jobCycleUseFormDefaultVal)}
                            style={{
                                width: '130px'
                            }}>
                            {t('jobCycle.clearBtnTitle')}
                        </ButtonComponent>
                        <ProgressButtonComponent
                            ref={submitRef}
                            cssClass="e-primary"
                            content={t('jobCycle.filterBtnTitle')}
                            duration={!isFetching ? 600 : 100000}
                            spinSettings={{
                                position: 'Center'
                            }}
                            animationSettings={{
                                effect: 'ZoomOut'
                            }}
                            style={{
                                width: '130px'
                            }}
                            onClick={handleSubmit(onSubmit)}
                        >
                        </ProgressButtonComponent>
                    </Stack>
                </div>
            </Grid>
        </Grid>
    );
})

const JobCycleFilter = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const timeout = useRef();
    const filterData = useRef(jobCycleUseFormDefaultVal);
    const filterFormRef = useRef();

    const animation = { expand: { effect: '', duration: 0, easing: '' }, collapse: { effect: '', duration: 0, easing: '' } };

    const handlExpanded = event => {
        if (timeout.current) clearTimeout(timeout.current);

        const isExpanded = event.isExpanded;
        if (!isExpanded && filterFormRef.current) {
            filterData.current = filterFormRef.current.getFilter();
        }
        else if (filterFormRef.current) {
            timeout.current = setTimeout(() => {
                filterFormRef.current.setFilter(filterData.current);
                timeout.current = null;
            }, 500);
        }
        setIsExpanded(isExpanded);
    }

    const getHeaderContent = () => !isExpanded ? new JobCycleFilterUtils(filterData.current).getFilterHeader() : 'Filter';

    return (
        <AccordionComponent expanded={handlExpanded} animation={animation} expandMode='Single'>
            <AccordionItemsDirective>
                <Loader />
                <AccordionItemDirective id='filter-acrdn' expanded={isExpanded} header={getHeaderContent()} content={() => (
                    <div className="e-card" id="basic_card" style={{ width: '100%' }}>
                        <div className="e-card-content">
                            <JobCycleFilterForm ref={filterFormRef} />
                        </div>
                    </div>
                )}></AccordionItemDirective>
            </AccordionItemsDirective>
        </AccordionComponent>
    );
}

export default memo(JobCycleFilter);