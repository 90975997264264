import { SpreadsheetComponent, SheetsDirective, SheetDirective, RowsDirective, RowDirective, CellsDirective, CellDirective, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-spreadsheet";
import { memo, useMemo } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { jobCycleSelector } from "../redux/job_cycle_slice";
import { shallowEqual, useSelector } from "react-redux";
import JobCycleFilterUtils from "../utils/filter";
import { useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { useEffect } from "react";
import { mapJobCycle } from "./Table";


const JobCycleExcel = forwardRef((_, ref) => {
    const { rawJobCycles, filter, downloadExcel } = useSelector(jobCycleSelector(['rawJobCycles', 'filter', 'downloadExcel']), shallowEqual);
    const mappedFilter = useMemo(() => new JobCycleFilterUtils(filter).mapToExcel(), [filter]);
    const mappedJobCycles = useMemo(() => mapJobCycle(rawJobCycles), [rawJobCycles]);

    const spreadsheetRef = useRef();
    const init = useRef(true);

    useImperativeHandle(ref, () => {
        return {
            download() {
                handleDownload();
            }
        }
    });

    //prevent from triggering when state is refreshed
    const triggerExcel = useRef(1)
    useEffect(() => {
        if (downloadExcel > triggerExcel.current && !init.current) {
            if ((downloadExcel - triggerExcel.current) > 0) triggerExcel.current = downloadExcel;
            handleDownload()
        }
        else init.current = false;
        // eslint-disable-next-line
    }, [downloadExcel]);

    const handleDownload = () => {
        Swal.fire({
            icon: 'info',
            title: 'Downloading Excel',
            text: 'Please wait...',
            backdrop: true,
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: async () => {
                Swal.showLoading();

                try {
                    await new Promise((resolve, reject) => {
                        spreadsheetRef.current.saveAsJson().then(response => {
                            let startDate, endDate = '';
                            if (filter.DateStart) startDate = `${moment(filter.DateStart).format('DD-MMM-yyyy')}`;
                            else startDate = `${moment().format('DD-MMM-yyyy')}`;
                            if (filter.DateEnd) endDate = `_${moment(filter.DateEnd).format('DD-MMM-yyyy')}`;

                            const fileName = `Readymix_Concrete_Brunei_Job_Cycle_${startDate}${endDate}`;
                            const extension = 'Xlsx';
                            var formData = new FormData();
                            formData.append('JSONData', JSON.stringify(response.jsonObject.Workbook));
                            formData.append('FileName', fileName);
                            formData.append('SaveType', extension);
                            formData.append('FileContentType', extension);
                            formData.append('VersionType', extension);
                            formData.append('PDFLayoutSettings', JSON.stringify({ "fitSheetOnOnePage": false }));

                            fetch(`https://findplus.w-locate.com/kimhock/scheduler/Report/ExtractReport`, {
                                method: 'POST',
                                body: formData
                            }).then(response => {
                                response.blob().then(data => {
                                    const url = URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
                                    const anchor = document.createElement('a');
                                    anchor.href = url;
                                    anchor.setAttribute('download', `${fileName}.${extension}`);
                                    document.body.appendChild(anchor);
                                    anchor.click();
                                    anchor.remove();
                                    resolve();
                                }, () => reject('Failed to generate file.'));
                            }, () => reject('Failed to Extract Report.'));
                        }, () => reject('Failed to saveAsJSON.'));
                    });

                    Swal.close();
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: error
                    });
                }
            }
        })
    }

    const sanitizeValue = (value, defaultVal) => value ? value : defaultVal ? defaultVal : ' ';
    const convertDate = value => value ? moment(value).format('DD-MMM-yyyy HH:mm') : '--'

    return (
        <div style={{ display: 'none' }}>
            <SpreadsheetComponent
                ref={spreadsheetRef}
                allowWrap={false}>
                <SheetsDirective>
                    <SheetDirective>
                        {/* <RangesDirective> */}
                        {/* <RangeDirective dataSource={dataSource} startCell={"A2"}></RangeDirective> */}
                        {/* </RangesDirective> */}
                        <RowsDirective>
                            <RowDirective>
                                <CellsDirective>
                                    <CellDirective value="Report"></CellDirective>
                                    <CellDirective colSpan={2} value="Job Cycle"></CellDirective>
                                </CellsDirective>
                            </RowDirective>
                            {mappedFilter.map(val => {
                                return <RowDirective key={val.title}>
                                    <CellsDirective>
                                        <CellDirective value={val.title}></CellDirective>
                                        <CellDirective colSpan={2} format="@" value={val.isDateTime ? convertDate(val.value) : val.value}></CellDirective>
                                    </CellsDirective>
                                </RowDirective>
                            })}
                            <RowDirective></RowDirective>
                            <RowDirective>
                                <CellsDirective>
                                    <CellDirective value='DIVISION'></CellDirective>
                                    <CellDirective value='GROUP'></CellDirective>
                                    <CellDirective value='VEHICLE' ></CellDirective>
                                    <CellDirective value='DRIVER'></CellDirective>
                                    <CellDirective value='CLIENT'></CellDirective>
                                    <CellDirective value='ORDER CODE'></CellDirective>
                                    <CellDirective value='TICKET NUMBER'></CellDirective>
                                    <CellDirective value='PLANT SITE'></CellDirective>
                                    <CellDirective value='PROJECT DETAILS'></CellDirective>
                                    <CellDirective value='MATERIAL'></CellDirective>
                                    <CellDirective value='QUANTITY'></CellDirective>
                                    <CellDirective value='CUMULATIVE QUANTITY'></CellDirective>
                                    <CellDirective value='UOM'></CellDirective>
                                    <CellDirective value='LOAD TIME'></CellDirective>
                                    <CellDirective value='LEFT PLANT'></CellDirective>
                                    <CellDirective value='ARRIVED AT SITE'></CellDirective>
                                    <CellDirective value='UNLOAD'></CellDirective>
                                    <CellDirective value='LEFT JOBSITE'></CellDirective>
                                    <CellDirective value='ARRIVED PLANT'></CellDirective>
                                    <CellDirective value='LOADING(min)'></CellDirective>
                                    <CellDirective value='TO SITE DURATION(min)'></CellDirective>
                                    <CellDirective value='WAITING TIME(min)'></CellDirective>
                                    <CellDirective value='UNLOADING(min)'></CellDirective>
                                    <CellDirective value='ON SITE DURATION(min)'></CellDirective>
                                    <CellDirective value='TO PLANT DURATION(min)'></CellDirective>
                                    <CellDirective value='CYCLE TIME(min)'></CellDirective>
                                    <CellDirective value='TO SITE DISTANCE(km)'></CellDirective>
                                    <CellDirective value='TO PLANT DISTANCE(km)'></CellDirective>
                                    <CellDirective value='CYCLE DISTANCE(km)'></CellDirective>
                                    <CellDirective value='JOB REMARKS'></CellDirective>
                                    <CellDirective value={' '}></CellDirective>
                                </CellsDirective>
                            </RowDirective>
                            {mappedJobCycles.map(jobCycle => (
                                <RowDirective key={jobCycle.TicketNum}>
                                    <CellsDirective>
                                        <CellDirective value={sanitizeValue(jobCycle.Division)}></CellDirective>
                                        <CellDirective value={sanitizeValue(jobCycle.Group)}></CellDirective>
                                        <CellDirective value={sanitizeValue(jobCycle.Vehicle)}></CellDirective>
                                        <CellDirective value={sanitizeValue(jobCycle.DriverName)}></CellDirective>
                                        <CellDirective value={sanitizeValue(jobCycle.SoldToName?.replace(/\s/g, ' '))}></CellDirective>
                                        <CellDirective format="@" value={sanitizeValue(jobCycle.OrderNum)}></CellDirective>
                                        <CellDirective value={sanitizeValue(jobCycle.TicketNum?.toString())}></CellDirective>
                                        <CellDirective value={sanitizeValue(jobCycle.FromPlant)}></CellDirective>
                                        <CellDirective value={sanitizeValue(`${jobCycle.ShipToCode} ${jobCycle.ShipToName}`)}></CellDirective>
                                        <CellDirective value={sanitizeValue(jobCycle.Material)}></CellDirective>
                                        <CellDirective format="@" value={sanitizeValue(jobCycle.Quantity)}></CellDirective>
                                        <CellDirective format="@" value={sanitizeValue(jobCycle.Cumulative)}></CellDirective>
                                        <CellDirective value={sanitizeValue(jobCycle.UOM)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(jobCycle.LoadTimestamp)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(jobCycle.ToSiteTimestamp)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(jobCycle.OnSiteTimestamp)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(jobCycle.UnloadTimestamp)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(jobCycle.ToPlantTimestamp)}></CellDirective>
                                        <CellDirective format="@" value={convertDate(jobCycle.OnPlantTimestamp)}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.LoadingDuration}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.ToSiteDuration}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.WaitingDuration}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.UnloadDuration}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.OnSiteDuration}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.ToPlantDuration}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.CycleTime}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.ToSiteDistance}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.ToPlantDistance}></CellDirective>
                                        <CellDirective format="@" value={jobCycle.CycleDistance}></CellDirective>
                                        <CellDirective format="@" value={sanitizeValue(jobCycle.JobRemarks)}></CellDirective>
                                        <CellDirective format="@" value={' '}></CellDirective>
                                    </CellsDirective>
                                </RowDirective>
                            ))}
                        </RowsDirective>
                        <ColumnsDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={80}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                            <ColumnDirective width={70}></ColumnDirective>
                        </ColumnsDirective>
                    </SheetDirective>
                </SheetsDirective>
            </SpreadsheetComponent>
        </div>
    );
});

export default memo(JobCycleExcel);