import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle } from 'react-feather';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import AthenaSettings, { AthenaSettingsKeys } from '../../utils/athena_settings';
import { RouterRoutes } from '../../App';

export default function PageNotFound() {
    const navigate = useNavigate();
    const [title, setTitle] = useState("Redirect")
    const hasToken = useRef(false);

    useEffect(() => {
        const settings = new AthenaSettings();
        const account = settings.getItem(AthenaSettingsKeys.account);
        const token = account?.JwtToken;
        setTitle(!token ? 'Redirect to Login' : 'Redirect to Job Cycle Page');
        hasToken.current = typeof token === 'string';
        // eslint-disable-next-line
    }, []);

    const handleOnClick = () => {
        if (hasToken.current) navigate(RouterRoutes.jobCycle);
        else navigate(RouterRoutes.login);
    }

    return (
        <div className="not-found-container">
            <div className="not-found">
                <AlertTriangle />
                <h1>Page is Unavailable</h1>
                <ButtonComponent cssClass='e-primary' onClick={handleOnClick}>{title}</ButtonComponent>
            </div>
        </div>
    );
}