import { LocalizationLng } from "../locales/i18n";

//Local Storage Object for Athena Website
export const AthenaSettingsKeys = {
    darkMode: "darkMode",
    version: "version",
    account: "account",
    rememberMe: "rememberMe",
    language: "language"
}

function AthenaSettings() {
    //default value
    this.value = {
        darkMode: false,
        version: 0,
        rememberMe: {
            value: false,
            date: new Date().toISOString()
        },
        account: null,
        language: LocalizationLng.en
    };

    this.setItem = function(key, value) {
        this.value = {
            ...this.value,
            [key]: value
        };
        
        this.saveChanges();
    }

    this.getItem = function(key) {
        try {
            return this.value[key];
        } catch (_) {
            return null;
        }
    }

    this.refresh = () => {
        this._init();
    }

    this.saveChanges = function() {
        localStorage.setItem(AthenaSettings.key, JSON.stringify(this.value));
    }

    this._init = function() {
        const athenaSettings = localStorage.getItem(AthenaSettings.key);
        const parsedSettings = athenaSettings ? JSON.parse(athenaSettings) : {};

        //Checks if new key value added on default value
        if (Object.keys(parsedSettings) !== Object.keys(this.value)) {
            this.value = {
                ...this.value,
                ...parsedSettings
            };
        }
    }
    this._init();
}

AthenaSettings.key = "athenaBruneiSettings";

export default AthenaSettings;