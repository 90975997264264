import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from '../container/login/redux/login_slice';
import jobCycleReducer from '../container/job_cycle/redux/job_cycle_slice';

const appReducer = combineReducers({
    login: loginReducer,
    jobCycle: jobCycleReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
