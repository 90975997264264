import { useEffect, useRef, useState } from 'react';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { useTranslation } from 'react-i18next';
import { LocalizationLng } from '../../locales/i18n';
import LoginForm from './components/LoginForm';
import ForgotPassword from './components/ForgotPassword';
import { useSelector } from 'react-redux';
import { loginStore } from './redux/login_slice';
import AthenaSettings, { AthenaSettingsKeys } from '../../utils/athena_settings';

export default function LoginPage() {
    const [ isEN, setIsEN ] = useState(LocalizationLng.en);
    const settings = useRef(new AthenaSettings());

    const { showLoginForm } = useSelector(loginStore);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        settings.current.refresh();
        const selectedLang = settings.current.value.language;
        i18n.changeLanguage(selectedLang);
        setIsEN(selectedLang === LocalizationLng.en);
        // eslint-disable-next-line
    }, []);

    const handleChange = args => {
        const lang = args.checked ? LocalizationLng.cn : LocalizationLng.en;
        i18n.changeLanguage(lang);
        settings.current.setItem(AthenaSettingsKeys.language, lang);
    }

    return (
        <div className="login-container">
            <div className="login-content">
                <div className="localization-container">
                    <p>{t('login.en')}</p>
                    <SwitchComponent checked={!isEN} change={handleChange} />
                    <p>{t('login.cn')}</p>
                </div>
                <div className="logo-container">
                    <img src="assets/W-locate_.png" alt="WLocate" />
                </div>
                <div className="center-content" style={{marginTop: '10px'}}>
                    <span className="title">READYMIX CONCRETE (BRUNEI)</span>
                </div>
                {showLoginForm && <LoginForm />}
                {!showLoginForm && <ForgotPassword />}
            </div>
        </div>
    );
}