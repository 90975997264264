import { axiosInstance } from "../../../utils/axios_config"

export default function IntegrationAccountService() {

    this.authenticate = async payload => {
        return axiosInstance.post('Account/Authenticate', payload);
    }

    this.getAccount = async () => {
        return axiosInstance.get(`Account`);
    }
}