import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import loginEn from './translations/login/login_en.json';
import loginCn from './translations/login/login_cn.json'
import jobCycleEn from './translations/job_cycle/job_cycle_en.json';
import jobCycleCn from './translations/job_cycle/job_cycle_cn.json'

export const LocalizationLng = {
    en: 'en',
    cn: 'cn'
}

i18n.use(initReactI18next)
    .init({
        lng: LocalizationLng.en,
        resources: {
            en: {
                translation: {
                    login: loginEn,
                    jobCycle: jobCycleEn
                }
            },
            cn: {
                translation: {
                    login: loginCn,
                    jobCycle: jobCycleCn
                }
            }
        },
        fallbackLng: LocalizationLng.en,
    });

export default i18n;