import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

export default function TableToolbar({onSearch}) {
    return (
        <div className="table-toolbar">
            <TextBoxComponent
                id="txtbox_table_search"
                cssClass="e-outline"
                placeholder="Search"
                floatLabelType='Auto'
                created={() => {
                    document.getElementById('txtbox_table_search').addEventListener('keypress', e => {
                        if (e.code === 'Enter') {
                            onSearch(e.target.value);
                        }
                    });
                }}
                width={'200px'} />
        </div>
    )
}